import React from "react";
import Logo from "../Logo/Logo";

import "./LogoWithText.scss";

const LogoWithText = () => {
  return (
    <div className="logo-with-text">
      <Logo/>
      <h1 className="logo-with-text__title">socialASSIST</h1>
    </div>
  );
};

export default LogoWithText;
