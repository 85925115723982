import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Modal from "../Modal/Modal";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import { Resource, ResourceStatus } from "../../utils/resource";
import ModalActionDto from "../Modal/modal-action.dto";
import BoilerDiagnosticsDto from "../../store/dtos/boiler-diagnostics.dto";

interface BoilerValuesReadWriteModalProps {
  diagnostics: BoilerDiagnosticsDto;
  actionResource: Resource;
  readValue: (diag: BoilerDiagnosticsDto) => any;
  writeValue: (value: string) => any;
  modalTitle: string;
  valueType?: string;
}

const propTypes: any = {
  diagnostics: PropTypes.instanceOf(BoilerDiagnosticsDto).isRequired,
  actionResource: PropTypes.instanceOf(Resource).isRequired,
  readValue: PropTypes.func.isRequired,
  writeValue: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  valueType: PropTypes.string
};

const BoilerValuesReadWriteModal = ({ diagnostics, actionResource, readValue, writeValue, modalTitle, valueType }: BoilerValuesReadWriteModalProps) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    setModalVisibility(false);
    setValue(null);
  }, [diagnostics]);

  if (diagnostics && value === null) {
    setValue(readValue(diagnostics));
  }

  // TODO Error handling on API failure

  return (
    <div>
      <button
        className="btn btn-outline-primary"
        onClick={() => {
          setModalVisibility(true);
          setValue(null);
          actionResource.setStatus(ResourceStatus.Initial);
        }}
      >
        Change
      </button>

      <Modal
        modalTitle={modalTitle}
        actions={[
          new ModalActionDto({
            text: "Write value",
            activate: () => {
              if (actionResource.isLoading()) {
                return;
              }

              actionResource.setStatus(ResourceStatus.Initial);
              actionResource
                .awaitValue()
                .then(() => {
                  setModalVisibility(false);
                })
                .catch(() => {});

              writeValue(value as any);
            }
          })
        ]}
        show={modalVisibility}
        close={() => setModalVisibility(false)}
      >
          {diagnostics ? (
            <div className="form-group">
              <label className="form-label" htmlFor="value">
                Value
              </label>
              <input
                className="form-control"
                id="value"
                type={valueType}
                value={value as string}
                onChange={e => setValue(e.target.value)}
              />
            </div>
          ) : null}

        {!actionResource.isInitial() ? (
          <LoadingWrapper
            resource={actionResource}
            errorMessage={
              <div className="alert alert-danger">Failed to update value</div>
            }
          />
        ) : null}
      </Modal>
    </div>
  );
};

BoilerValuesReadWriteModal.propTypes = propTypes;

BoilerValuesReadWriteModal.defaultProps = {
  valueType: "text"
};

export default BoilerValuesReadWriteModal;
