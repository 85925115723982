import React, { FormEvent, useCallback, useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Resource } from "../../utils/resource";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { State } from "../../store";
import { getResetPasswordResource, resetPasswordSubmitted, submitResetPassword } from "../../store/session";
import Input from "../../components/Input/Input";

import "./SignIn.scss";

interface ForgotPasswordProps {
  submitResetPassword: CallableFunction;
  resetPasswordSubmitted: CallableFunction;
  resetPasswordResource: Resource<boolean>;
}

const propTypes: any = {
  submitResetPassword: PropTypes.func.isRequired,
  resetPasswordSubmitted: PropTypes.func.isRequired,
  resetPasswordResource: PropTypes.instanceOf(Resource).isRequired
};

const ResetPassword = ({ submitResetPassword, resetPasswordResource }: ForgotPasswordProps) => {
  const [username, setUsername] = useState("");

  useEffect(() => {
    resetPasswordSubmitted(new Resource());
  }, [submitResetPassword]);

  const submit = useCallback((e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    submitResetPassword(username);
    return false;
  }, [submitResetPassword, username]);

  return (
    <div className="reset-password">
      <div className="auth-page__title mt-3">Reset password</div>

      {resetPasswordResource.isLoaded() ? (
        <p>You'll receive an email shortly.</p>
      ) : (
        <>
          <p>Please enter your email address to reset your password.</p>

          <form onSubmit={e => submit(e)}>
            <Input label="Email address" onChange={val => setUsername(val)} value={username}/>

            {resetPasswordResource.isErrored() ? (
              <div className="alert alert-danger">Unable to reset your password now</div>
            ) : null}

            {resetPasswordResource.isLoading() ? (
              <div className="d-flex align-items-center justify-content-center mt-3 py-2">
                <Loading/>
              </div>
            ) : (
              <button type="submit" className="btn btn-secondary btn-block mt-3">
                Reset password
              </button>
            )}
          </form>
        </>
      )}
    </div>
  );
};

ResetPassword.propTypes = propTypes;

ResetPassword.defaultProps = {};

export default connect((state: State) => ({
  resetPasswordResource: getResetPasswordResource(state)
}), {
  submitResetPassword,
  resetPasswordSubmitted
})(ResetPassword);
