import BoilerDto from "./store/dtos/boiler.dto";
import { WaterPressureDto } from "./store/dtos/water-pressure.dto";
import { SignUpDto } from "./store/dtos/sign-up.dto";
import RequestError from "./Exceptions/request.error";

const BASE_URL = `/api/vaillant`;

class API {
  request = async <T>(route: string, method = "GET", data: any = null): Promise<T | undefined> => {
    const fetchOptions: any = { method };

    if (data) {
      fetchOptions.body = JSON.stringify(data);
      fetchOptions.headers = { "Content-Type": "application/json" };
    }

    const response = await fetch(route, fetchOptions);
    if (response.status < 200 || response.status >= 400) {
      let error;
      try{
        const body = await response.json();
        error = new RequestError(response.status.toString(), body);
      } catch(err){
        error = new Error(response.status.toString());
      }
      throw error;
    }

    return await response.json();
  };

  signIn = async (username: string, password: string) => {
    return await this.request(`/auth/sign-in`, "POST", {
      username,
      password
    });
  };

  signUp = async (dto: SignUpDto) => {
    return await this.request(`/auth/sign-up`, "POST", dto);
  };

  checkAuth = async () => {
    return await this.request(`/auth/check`, "GET");
  }

  signOut = async () => {
    return await this.request(`/auth/sign-out`, "POST");
  }

  submitResetPassword = async (username: string) => {
    return await this.request(`/auth/reset-password`, "POST", { username });
  };

  getBoilers = async (search: string = "") => {
    const results = await this.request(`${BASE_URL}/?search=${search}`) as BoilerDto[];
    return results.map(boiler => new BoilerDto(boiler));
  };

  getBoiler = async (serialNumber: string) => {
    const result = await this.request(`${BASE_URL}/${serialNumber}`) as BoilerDto;
    return new BoilerDto(result);
  };

  addBoiler = async (serialNumber: string, contractNumber: string) => {
    return await this.request(BASE_URL, "POST", { serialNumber, contractNumber });
  };

  updateBoilerSettings = async (gatewaySerialNumber: string, serialNumber: string, data: any) => {
    return await this.request(`${BASE_URL}/${serialNumber}`, "PUT", {
      serialNumber,
      gatewaySerialNumber,
      update: data
    });
  };

  updateUprn = async (serialNumber: string, uprn: string) => {
    return await this.request(`${BASE_URL}/${serialNumber}/uprn`, "POST", {
      serialNumber,
      uprn
    })
  }

  getWaterPressureHistory = async (gatewaySerialNumber: string, serialNumber: string) => {
    const results = await this.request(`${BASE_URL}/${serialNumber}/water-pressure?serialNumber=${serialNumber}&gatewaySerialNumber=${gatewaySerialNumber}`) as WaterPressureDto[];
    return results.map(history => new WaterPressureDto(history));
  };
}

export default new API();
