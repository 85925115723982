import React from "react";
import PropTypes from "prop-types";
import ModalActionDto from "./modal-action.dto";

import "./Modal.scss";

interface ModalProps {
  children: any;
  modalTitle: string;
  show: boolean;
  actions: ModalActionDto[];
  close: CallableFunction;
}

const propTypes: any = {
  children: PropTypes.node,
  modalTitle: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.instanceOf(ModalActionDto)).isRequired,
  close: PropTypes.func.isRequired
};

const Modal = ({ children, actions, close, show, modalTitle }: ModalProps) => {
  return (
    <div className={"boiler-modal" + (show ? " show" : "")}>
      <div className="modal fade show">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0 bg-light">
              <h5 className="modal-title">{modalTitle}</h5>
              <button className="close" onClick={() => close()}>
                &times;
              </button>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer border-0 bg-light d-flex align-items-center justify-content-between">
              <button className="btn btn-light" onClick={() => close()}>
                Close
              </button>
              <div className="d-flex align-items-center">
                {actions.map((action, i) => (
                  <button
                    className="btn btn-primary ml-2"
                    key={i}
                    onClick={() => action.activate()}
                  >
                    {action.text}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"/>
    </div>
  );
};

Modal.propTypes = propTypes;

Modal.defaultProps = {};

export default Modal;
