import React from "react";
import socialAssistLogo from "../../assets/images/socialASSIST.svg";

import "./Logo.scss";

const Logo = () => {
  return (
    <img src={socialAssistLogo} alt="logo" className="logo"/>
  );
};

export default Logo;
