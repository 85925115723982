import React, { FormEvent, useCallback, useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Resource } from "../../utils/resource";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { State } from "../../store";
import { getSignedIn, signedIn, signUp } from "../../store/session";
import Input, { InputType } from "../../components/Input/Input";
import { SignUpDto } from "../../store/dtos/sign-up.dto";

import "./SignUp.scss";

interface SignUpProps {
  signUp: CallableFunction;
  signedIn: CallableFunction;
  signedInResource: Resource<boolean>;
}

const propTypes: any = {
  signUp: PropTypes.func.isRequired,
  signedIn: PropTypes.func.isRequired,
  signedInResource: PropTypes.instanceOf(Resource).isRequired
};

const SignUp = ({ signedIn, signedInResource, signUp }: SignUpProps) => {
  const [dto, setDto] = useState<SignUpDto>({
    address: { city: "", country: "", countryCode: "", houseNumber: "", postalCode: "", street: "" },
    businessName: "",
    businessPhone: "",
    email: "",
    firstName: "",
    lastName: "",
    marketing: false,
    password: "",
    phone: "",
    salutation: ""
  });

  useEffect(() => {
    signedIn(new Resource());
  }, [signedIn]);


  const setDtoValue = useCallback((key: keyof SignUpDto, value: any) => {
    setDto({ ...dto, [key]: value });
  }, [dto, setDto]);

  const setAddressDtoValue = useCallback((key: string, value: any) => {
    dto.address = { ...dto.address, [key]: value };
    setDto({ ...dto });
  }, [dto, setDto]);

  const submit = useCallback((e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    signUp(dto);
    return false;
  }, [signUp, dto]);

  return (
    <div className="sign-up">
      <div className="auth-page__title mt-3">Sign up</div>

      <form onSubmit={e => submit(e)} className="text-left">
        <div className="mt-3 mb-1">
          <strong>Personal details</strong>
        </div>

        <Input label="Email address" placeholder="john@doe.co.uk" value={dto.email} onChange={val => setDtoValue("email", val)}/>
        <Input label="Password" placeholder="••••••••" value={dto.password} type={InputType.Password} onChange={val => setDtoValue("password", val)}/>

        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="salutation" className="form-label">Title</label>
              <select name="salutation" id="salutation" className="form-control">
                <option value="">-</option>
                <option value="mr">Mr</option>
                <option value="ms">Ms</option>
                <option value="miss">Miss</option>
                <option value="mrs">Mrs</option>
              </select>
            </div>
          </div>
          <div className="col-8"><Input label="First name" placeholder="John" value={dto.firstName} onChange={val => setDtoValue("firstName", val)}/></div>
          <div className="col-12"><Input label="Last name" placeholder="Doe" value={dto.lastName} onChange={val => setDtoValue("lastName", val)}/></div>
          <div className="col-12"><Input label="Phone number" placeholder="012345678" value={dto.phone} onChange={val => setDtoValue("phone", val)}/></div>
        </div>

        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="marketing-consent"/>
          <label className="form-check-label" htmlFor="marketing-consent">I'd like to receive marketing emails</label>
        </div>

        <div className="mt-3 mb-1">
          <strong>Business details</strong>
        </div>

        <div className="row">
          <div className="col-12"><Input label="Business name" placeholder="My Organisation" value={dto.businessName} onChange={val => setDtoValue("businessName", val)}/></div>
          <div className="col-12"><Input label="Business phone" placeholder="012345678" value={dto.businessPhone} onChange={val => setDtoValue("businessPhone", val)}/></div>
          <div className="col-4"><Input label="House no." placeholder="1" value={dto.address.houseNumber} onChange={val => setAddressDtoValue("houseNumber", val)}/></div>
          <div className="col-8"><Input label="Street name" placeholder="My Street" value={dto.address.street} onChange={val => setAddressDtoValue("street", val)}/></div>
          <div className="col-6"><Input label="City" placeholder="My City" value={dto.address.city} onChange={val => setAddressDtoValue("city", val)}/></div>
          <div className="col-6"><Input label="Postal Code" placeholder="Postal Code" value={dto.address.postalCode} onChange={val => setAddressDtoValue("postalCode", val)}/></div>
          <div className="col-12"><Input label="Country" placeholder="My Country" value={dto.address.country} onChange={val => setAddressDtoValue("country", val)}/></div>
        </div>

        {signedInResource.isErrored() ? (
          <div className="alert alert-danger">Cannot register this account now, please try again later.</div>
        ) : null}

        {signedInResource.isLoading() ? (
          <div className="d-flex align-items-center justify-content-center mt-3 py-2">
            <Loading/>
          </div>
        ) : (
          <button type="submit" className="btn btn-secondary btn-block mt-3">
            Sign up
          </button>
        )}
      </form>
    </div>
  );
};

SignUp.propTypes = propTypes;

SignUp.defaultProps = {};


export default connect((state: State) => ({
  signedInResource: getSignedIn(state)
}), {
  signUp,
  signedIn
})(SignUp);
