import React from "react";
import PropTypes from "prop-types";
import BoilerDto from "../../store/dtos/boiler.dto";
import { Link } from "react-router-dom";

interface ColumnWrapperProps {
  row: { data: BoilerDto; };
  cell: { key: string; value: any; };
}

const propTypes: any = {
  row: PropTypes.shape({
    data: PropTypes.instanceOf(BoilerDto)
  }).isRequired,
  cell: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.any
  }).isRequired
};

const ColumnWrapper = ({ row, cell }: ColumnWrapperProps) => {
  const boiler = row.data;
  const { system } = boiler;

  let child = null;
  if (cell.key === "serialNumber") {
    if (!system) {
      child = (
        <div className="text-danger">
          {boiler.serialNumber}: Not Available
        </div>
      );
    } else {
      child = (
        <Link to={"/" + boiler.serialNumber}>{boiler.serialNumber}</Link>
      );
    }
  } else if (system) {
    child = <>{cell.value}</>;
  }

  return <td key={cell.key}>{child}</td>;
};

ColumnWrapper.propTypes = propTypes;

ColumnWrapper.defaultProps = {};

export default ColumnWrapper;
