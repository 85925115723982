import * as React from "react";
import { Provider } from "react-redux";

import { store } from "./store";
import Routing from "./pages/Routing/Routing";
import Header from "./components/Header/Header";

import "./App.scss";

const App = () => {
  return (
    <Provider store={store}>
      <Header/>
      <Routing/>
    </Provider>
  );
};

export default App;
