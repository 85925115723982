import React, { useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import LogoWithText from "../../components/LogoWithText/LogoWithText";
import ResetPassword from "./ResetPassword";
import Modal from "../../components/Modal/Modal";

import "./AuthPage.scss";

enum View {
  SignIn,
  SignUp,
  ResetPassword
}

const propTypes: any = {};

const AuthPage = () => {
  const [view, setView] = useState(View.SignIn);
  const [showResetPassword, setShowResetPassword] = useState(false);

  return (
    <div className="auth-page">
      <Modal
        modalTitle="Reset password"
        show={showResetPassword}
        close={()=>setShowResetPassword(false)}
        actions={[]}
      >
        To reset your password, please contact your sales manager.
      </Modal>
      <LogoWithText/>
      {
        view === View.SignIn
          ? (<SignIn/>) :
          view === View.SignUp
          ? (<SignUp/>) :
            (<ResetPassword/>)
      }


      <div className="auth-page__set-view">
        {view === View.SignIn && (
          <>
            <button className="btn btn-link" onClick={() => setShowResetPassword(true)}>Forgot your password?</button>
            <button className="btn btn-link" onClick={() => setView(View.SignUp)}>Create an account</button>
          </>
        )}

        {view === View.SignUp && (
          <button className="btn btn-link" onClick={() => setView(View.SignIn)}>Already a member?</button>
        )}

        {view === View.ResetPassword && (
          <button className="btn btn-link" onClick={() => setView(View.SignIn)}>Sign in instead</button>
        )}

      </div>
    </div>
  );
};

AuthPage.propTypes = propTypes;

AuthPage.defaultProps = {};

export default AuthPage;
