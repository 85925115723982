import { useState } from "react";

interface UsePaginationOptions {
  data: any[];
  columns: any[];
  pageSize?: number;
}

const usePagination = ({ data, columns, pageSize = 10 }: UsePaginationOptions) => {
  const [sortingBy, setSortingBy] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [previousLength, setPreviousLength] = useState(0);

  const pages = Math.ceil(data.length / pageSize);

  const gotoPage = (index: number) => {
    let newIndex = index;
    if (index < 0) {
      newIndex = 0;
    } else if (pages <= index) {
      newIndex = pages - 1;
    }

    setCurrentPage(newIndex);
  };

  const sortBy = (cellId: string) => {
    setSortingBy(value => {
      switch (value) {
        case cellId:
          return "-" + cellId;
        case "-" + cellId:
          return null;
        default:
          return cellId;
      }
    });
  };

  if (previousLength !== data.length) {
    if (previousLength < data.length && currentPage > 0) {
      setCurrentPage(0);
    }

    setPreviousLength(data.length);
  }

  let returnData = data.slice(0);
  let sortingByKey: string;
  if (typeof sortingBy === "string") {
    sortingByKey = sortingBy[0] === "-" ? sortingBy.substr(1) : sortingBy;

    const mode = sortingBy[0] === "-" ? 1 : -1;
    const cell = columns.find(col => (col.key === sortingByKey));

    returnData.sort((el1, el2) => {
      const val1 = (cell.getSortValue ? cell.getSortValue(el1) : cell.getValue(el1)) || "";
      const val2 = (cell.getSortValue ? cell.getSortValue(el2) : cell.getValue(el2)) || "";

      return val1 < val2 ? mode : -mode;
    });
  }

  if (returnData.length > pageSize) {
    const index = pageSize * currentPage;
    returnData = returnData.slice(index, index + pageSize);
  }

  return {
    headers: columns.map(column => {
      return {
        key: column.key,
        text: column.header,
        isSorted: sortingByKey === column.key,
        isSortedDesc: sortingByKey && typeof sortingBy === "string" && sortingBy[0] === "-"
      };
    }),
    rows: returnData.map(data => {
      return {
        data,
        cells: columns.map(column => ({
          key: column.key,
          value: column.getValue(data)
        }))
      };
    }),

    pageSize,
    pageIndex: currentPage,
    canPreviousPage: currentPage > 0,
    canNextPage: currentPage < pages - 1,

    sortBy,
    gotoPage
  };
};

export default usePagination;
