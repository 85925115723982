import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BoilerDto from "../../store/dtos/boiler.dto";
import BoilerWaterPressureChart from "../BoilerWaterPressureChart/BoilerWaterPressureChart";
import BoilerValuesReadWriteModal from "./BoilerValuesReadWriteModal";

import "./BoilerValues.scss";
import { boilerWriteCentralHeatingOutput, boilerWriteDomesticHotWaterOutput, boilerWriteServiceTimer } from "../../store/boilers";

interface BoilerValuesProps {
  boilerWriteServiceTimer: CallableFunction;
  boilerWriteDomesticHotWaterOutput: CallableFunction;
  boilerWriteCentralHeatingOutput: CallableFunction;
  boiler: BoilerDto;
}

const propTypes: any = {
  boilerWriteServiceTimer: PropTypes.func.isRequired,
  boilerWriteDomesticHotWaterOutput: PropTypes.func.isRequired,
  boilerWriteCentralHeatingOutput: PropTypes.func.isRequired,
  boiler: PropTypes.instanceOf(BoilerDto).isRequired
};

const BoilerValues = ({ boilerWriteServiceTimer, boilerWriteDomesticHotWaterOutput, boilerWriteCentralHeatingOutput, boiler }: BoilerValuesProps) => {
  const [pressureChartVisible, setPressureChartVisible] = useState(false);

  const diagnostics = boiler.getBoilerDiagnostics();

  return (
    <div className="boiler-values">
      <BoilerWaterPressureChart
        boiler={boiler}
        show={pressureChartVisible}
        close={() => setPressureChartVisible(false)}
      />

      {diagnostics ? (
        <table className="table table-hover table-bordered">
          <tbody>
          <tr>
            <td>Boiler</td>
            <td>Service Timer (D84)</td>
            <td>{diagnostics.hoursTillService ? `${diagnostics.hoursTillService} hours` : `Unknown`}</td>
            <td>
              <BoilerValuesReadWriteModal
                diagnostics={diagnostics}
                actionResource={diagnostics.updateServiceTimerAction}
                readValue={diag => parseInt(diag.hoursTillService || "", 10)}
                writeValue={value => boilerWriteServiceTimer(boiler, value)}
                modalTitle="Change service timer"
                valueType="number"
              />
            </td>
          </tr>
          <tr>
            <td>Boiler</td>
            <td>Domestic Hot Water (D77)</td>
            <td>{diagnostics.domesticHotWater.powerOutput}kW</td>
            <td>
              <BoilerValuesReadWriteModal
                diagnostics={diagnostics}
                actionResource={diagnostics.updateDomesticHotWaterAction}
                readValue={diag => diag.domesticHotWater.powerOutput || ""}
                writeValue={value => boilerWriteDomesticHotWaterOutput(boiler, value ? parseInt(value, 10) : null)}
                modalTitle="Change Hot Water Output (kW)"
              />
            </td>
          </tr>
          <tr>
            <td>Boiler</td>
            <td>Central Heating (D0)</td>
            <td>{diagnostics.centralHeating.powerOutput}kW</td>
            <td>
              <BoilerValuesReadWriteModal
                diagnostics={diagnostics}
                actionResource={diagnostics.updateCentralHeatingAction}
                readValue={diag => diag.centralHeating.powerOutput || ""}
                writeValue={value => boilerWriteCentralHeatingOutput(boiler, value ? parseInt(value, 10) : null)}
                modalTitle="Change Central Heating Output (kW)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      ) : null}

      <div className="text-right mt-3">
        <button
          className="btn btn-primary"
          onClick={() => setPressureChartVisible(true)}
        >
          Water pressure
        </button>
      </div>
    </div>
  );
};

BoilerValues.propTypes = propTypes;

BoilerValues.defaultProps = {};

export default connect(null, {
  boilerWriteServiceTimer,
  boilerWriteDomesticHotWaterOutput,
  boilerWriteCentralHeatingOutput
})(BoilerValues);
