import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSignedIn } from "../../store/session";
import { State } from "../../store";
import { Resource } from "../../utils/resource";
import { Link } from "react-router-dom";

import "./ErrorPage.scss";
import dreamer from "../../assets/images/undraw_dreamer_gxxi.svg"

interface ErrorPageProps {
  signedIn: Resource;
}

const propTypes = {
  signedIn: PropTypes.instanceOf(Resource).isRequired
};

const ErrorPage: any = ({ signedIn }: ErrorPageProps) => {
  return (
    <div className="error-page">
      <img src={dreamer} alt="Error" />

      <h1>There was an error.</h1>
      <p>
        We have encountered an unexpected error. If the error persists, please
        contact us.
      </p>
      <div>
        <Link to="/" className="btn btn-light">Return to main page</Link>
      </div>
    </div>
  );
};

ErrorPage.propTypes = propTypes;

ErrorPage.defaultProps = {};

export default connect((state: State) => ({
  signedIn: getSignedIn(state)
}))(ErrorPage);
