import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { addBoiler, getBoilers, loadBoilers } from "../../store/boilers";
import BoilerDto from "../../store/dtos/boiler.dto";
import ListPagination from "./ListPagination";
import Modal from "../Modal/Modal";
import ModalActionDto from "../Modal/modal-action.dto";
import usePagination from "../../utils/custom-hooks/usePagination";
import BoilerListStatus from "./BoilerListStatus";

import "./BoilerList.scss";
import BoilerListShortDescriptionNotification from "./BoilerListShortDescriptionNotification";
import BoilerListRow from "./BoilerListRow";
import { State } from "../../store";
import { Resource } from "../../utils/resource";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import { NotificationType } from "../../store/dtos/notification.dto";

interface BoilerListProps {
  loadBoilers: CallableFunction;
  addBoiler: CallableFunction;
  boilersResource: Resource<BoilerDto[]>;
}

const propTypes: any = {
  loadBoilers: PropTypes.func.isRequired,
  addBoiler: PropTypes.func.isRequired,
  boilersResource: PropTypes.instanceOf(Resource).isRequired
};

/* eslint react/jsx-key: 0 */
/* eslint react/display-name: 0 */
// we're disabling the rule here because all the keys are handled by react-table, but eslint can't see that
const BoilerList = ({ boilersResource, addBoiler, loadBoilers }: BoilerListProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [previousLength, setPreviousLength] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  useEffect(() => {
    if (boilersResource.isInitial()) {
      loadBoilers(searchValue);
    }
  }, [boilersResource, loadBoilers, searchValue]);

  const boilers = boilersResource.getValue() as BoilerDto[];
  if (boilers && previousLength !== boilers.length) {
    if (previousLength < boilers.length && currentPageIndex > 0) {
      setCurrentPageIndex(0);
    }

    setPreviousLength(boilers.length);
  }

  const {
    headers,
    rows,
    pageIndex,
    pageSize,
    canNextPage,
    canPreviousPage,
    sortBy,
    gotoPage
  } = usePagination({
    data: boilers || [],
    columns: [
      {
        header: "Serial Number",
        key: "serialNumber",
        getValue: (boiler: BoilerDto) => boiler.serialNumber
      },
      {
        header: "Status",
        key: "status",
        getValue: (boiler: BoilerDto) => <BoilerListStatus boiler={boiler}/>,
        getSortValue: (boiler: BoilerDto) => {

          const failures = boiler.getFailures();
          if (!failures) {
            return null;
          }
          if (!failures.length) {
            return "Ok";
          }

          return failures[0].type === NotificationType.Warning ? `Warning ${failures[0].getShortType()}.${failures[0].code}` : `Failure ${failures[0].getShortType()}.${failures[0].code}`;

        }
      },
      {
        header: "Short Description",
        key: "description",
        getValue: (boiler: BoilerDto) => {
          const failures = boiler.getFailures();
          if (failures && failures.length) {
            return failures.map((notification, i) => (
              <BoilerListShortDescriptionNotification notification={notification} key={i}/>
            ));
          }

          return null;
        },
        getSortValue: (boiler: BoilerDto) => {
          const failures = boiler.getFailures();
          if (failures && failures.length) {
            return failures[0].description || "Not Available";
          }

          return null;
        }
      },
      {
        header: "Service",
        key: "service",
        getValue: (boiler: BoilerDto) => {
          const diagnostics = boiler.getBoilerDiagnostics();
          if (!diagnostics) {
            return null;
          }

          const { hoursTillService } = diagnostics;
          if (hoursTillService === null || hoursTillService === void 0) {
            return "Not available";
          }

          return moment()
            .add(parseInt(hoursTillService, 10), "hours")
            .format("DD/MM/YYYY");
        }
      }
    ]
  });

  return (
    <section className="boiler-list">
      <Modal
        modalTitle="Add boiler"
        show={addModalVisible}
        close={() => setAddModalVisible(false)}
        actions={[
          new ModalActionDto({
            text: "Add boiler",
            activate: () => {
              addBoiler(serialNumber, contractNumber);
              setSerialNumber("");
              setContractNumber("");
              setAddModalVisible(false);
            }
          })
        ]}
      >
        <div className="form-group">
          <label htmlFor="serial-number" className="form-label">
            Serial number
          </label>
          <input
            id="serial-number"
            placeholder="Serial number..."
            className="form-control"
            value={serialNumber}
            onChange={e => setSerialNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contract-number" className="form-label">
            Contract Number <small>(If you do not have it, contact your Sales Representative)</small>
          </label>
          <input
            id="contract-number"
            placeholder="Contract Number"
            className="form-control"
            value={contractNumber}
            onChange={e => setContractNumber(e.target.value)}
          />
        </div>
      </Modal>

      <div className="d-flex justify-content-between align-items-end mb-2">
        <div className="search-bar d-flex align-items-center">
          <div className="search-bar__search mr-2">
            <input
              placeholder="Search"
              className="form-control"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </div>

          <button
            className="btn btn-secondary mr-2"
            onClick={() => loadBoilers(searchValue)}
          >
            Search
          </button>

          <button
            className="btn btn-secondary"
            onClick={() => setAddModalVisible(true)}
          >
            Add Boiler
          </button>
        </div>

        {boilersResource.isLoaded() ? (
          <ListPagination
            pageSize={pageSize}
            total={boilers.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageIndex={pageIndex}
          />
        ) : null}
      </div>

      <LoadingWrapper resource={boilersResource} errorMessage="Unable to load assets">
        {boilersResource.isLoaded() ? (
          <>
            <table>
              <thead>
              <tr>
                {headers.map((header: any, i: number) => (
                  <th key={i} onClick={() => sortBy(header.key)}>
                    {header.text}

                    <span
                      className={
                        "sort-icon ml-1 badge badge-pill badge-light" +
                        (header.isSorted ? "" : " invisible")
                      }
                    >
                    {header.isSorted && header.isSortedDesc ? "↓" : "↑"}
                  </span>
                  </th>
                ))}
              </tr>
              </thead>
              <tbody>
              {rows.map((row: any, i: number) => (
                <BoilerListRow row={row} key={i}/>
              ))}
              </tbody>
            </table>

            <div className="py-2">
              <ListPagination
                pageSize={pageSize}
                total={boilers.length}
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
              />
            </div>
          </>
        ) : null}
      </LoadingWrapper>
    </section>
  );
};

BoilerList.propTypes = propTypes;

BoilerList.defaultProps = {};

export default connect((state: State) => ({
  boilersResource: getBoilers(state)
}), {
  loadBoilers,
  addBoiler
})(BoilerList);
