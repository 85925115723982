import React from "react";
import PropTypes from "prop-types";
import BoilerDto from "../../store/dtos/boiler.dto";

import "./BoilerMonitoring.scss";

interface BoilerMonitoringProps {
  boiler: BoilerDto;
}

const propTypes: any = {
  boiler: PropTypes.instanceOf(BoilerDto).isRequired
};

const downloadCsv = (filename: string, fieldset: any) => {
  const fieldStr = Object.keys(fieldset).join(",") + "\n" + Object.values(fieldset);

  const element = document.createElement("a");
  element.setAttribute("href", "data:text/octet-stream;charset=utf-8," + encodeURIComponent(fieldStr));
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};

const BoilerMonitoring = ({ boiler }: BoilerMonitoringProps) => {
  const diagnostics = boiler.getBoilerDiagnostics();
  let fieldset: any;

  if (diagnostics) {
    fieldset = {
      "Flow temperature": diagnostics.centralHeating.temperatures.flowTemperature + " °C",
      "Return temperature": diagnostics.centralHeating.temperatures.returnTemperature + " °C",
      "Water pressure": diagnostics.centralHeating.waterPressure.toFixed(2) + " bar",
      "Modulation rate": diagnostics.modulationLevel ? (diagnostics.modulationLevel + " %") : "Unknown",
      "3-port diverter valve": diagnostics.getThreeWayValveText(),
      "Heat demand": diagnostics.heatingDemand ? "Internal demand" : "No internal demand",
      "Average Ignition Time": diagnostics.averageIgnitionTime + " s",
      "Generator Status": diagnostics.generatorStatus,
      "Central heating (status)": diagnostics.centralHeating.blocked ? "Blocked" : "Open",
      "Central heating (Operating hours)": diagnostics.centralHeating.operatingHours + " hours",
      "Central heating (Burner starts)": diagnostics.centralHeating.burnerStarts,
      "Hot Water (Operator hours)": diagnostics.domesticHotWater.operatingHours + " hours",
      "Hot water (Burner starts)": diagnostics.domesticHotWater.burnerStarts,
    };
  }

  return (
    <div className="boiler-monitoring">
      <div className="h5 mb-3">Monitoring Details</div>

      {diagnostics && (
        <div className="boiler-monitoring-table">
          {Object.keys(fieldset).map((key, i) => (
            <div key={i} className="tr">
              <div className="td">{key}</div>
              <div className="td">
                <strong>{fieldset[key]}</strong>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="d-flex align-items-center justify-content-end mt-3">
        <button className="btn btn-primary ml-2" onClick={() => {
          if (diagnostics) {
            downloadCsv("boiler-export.csv", fieldset);
          }
        }}>
          Export
        </button>
      </div>
    </div>
  );
};

BoilerMonitoring.propTypes = propTypes;

BoilerMonitoring.defaultProps = {};

export default BoilerMonitoring;
