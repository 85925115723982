import React from "react";
import PropTypes from "prop-types";
import NotificationDto from "../../store/dtos/notification.dto";

interface BoilerListShortDescriptionNotificationProps {
  notification: NotificationDto;
}

const propTypes: any = {
  notification: PropTypes.instanceOf(NotificationDto).isRequired
};

const BoilerListShortDescriptionNotification = ({ notification }: BoilerListShortDescriptionNotificationProps) => {
  if (notification.description) {
    return <div>{notification.description}</div>;
  }

  return <div>Not available</div>;
};

BoilerListShortDescriptionNotification.propTypes = propTypes;

BoilerListShortDescriptionNotification.defaultProps = {};

export default BoilerListShortDescriptionNotification;
