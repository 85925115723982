import React from "react";
import PropTypes from "prop-types";

import BoilerDto from "../../store/dtos/boiler.dto";
import NotificationDto from "../../store/dtos/notification.dto";

interface BoilerCodeProps {
  boiler: BoilerDto;
}

const propTypes: any = {
  boiler: PropTypes.instanceOf(BoilerDto).isRequired
};

const BoilerCode = ({ boiler }: BoilerCodeProps) => {
  const { notifications } = boiler;

  let notification: NotificationDto | null = null;
  if (notifications) {
    notification = notifications[0];
  }

  return (
    <div className="boiler-code">
      <div className="h5 mb-3">Code</div>
      <div className="bg-light p-3">
        <div>
          <div className="h1">
            {notification
              ? notification.getShortType() + "." + notification.code
              : "OK"}
          </div>
          {notification ? (
            <div className="small text-muted">
              {notification.description}
            </div>
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
  );
};

BoilerCode.propTypes = propTypes;

BoilerCode.defaultProps = {};

export default BoilerCode;
