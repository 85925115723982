import React from "react";
import PropTypes from "prop-types";
import ColumnWrapper from "./ColumnWrapper";
import BoilerDto from "../../store/dtos/boiler.dto";
import { RouteComponentProps, withRouter } from "react-router";

interface BoilerListRowProps extends RouteComponentProps<any> {
  row: {
    data: BoilerDto;
    cells: any[];
  }
};

const propTypes: any = {
  row: PropTypes.shape({
    data: PropTypes.instanceOf(BoilerDto).isRequired,
    cells: PropTypes.arrayOf(PropTypes.any).isRequired
  }).isRequired
};

const BoilerListRow = ({ row, history }: BoilerListRowProps) => {

  const { system } = row.data;

  const onClick = system ? () => history.push("/" + row.data.serialNumber) : undefined;

  return (
    <tr onClick={onClick}>
      {row.cells.map((cell, i) => (
        <ColumnWrapper key={i} cell={cell} row={row}/>
      ))}
    </tr>
  );
};

BoilerListRow.propTypes = propTypes;

BoilerListRow.defaultProps = {};

export default withRouter(BoilerListRow);
