import React, { FormEvent, useCallback, useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Resource } from "../../utils/resource";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { State } from "../../store";
import { getSignedIn, signedIn, signIn, getResetPasswordResource } from "../../store/session";

import "./SignIn.scss";

interface SignInProps {
  signIn: CallableFunction;
  signedIn: CallableFunction;
  signedInResource: Resource<boolean>;
  resetPasswordResource: Resource<boolean>;
}

const propTypes: any = {
  signIn: PropTypes.func.isRequired,
  signedIn: PropTypes.func.isRequired,
  signedInResource: PropTypes.instanceOf(Resource).isRequired,
  resetPasswordResource: PropTypes.instanceOf(Resource).isRequired
};

const SignIn = ({ signedIn, signIn, signedInResource, resetPasswordResource }: SignInProps) => {
  const [usernameValue, changeUsernameValue] = useState("");
  const [passwordValue, changePasswordValue] = useState("");

  useEffect(() => {
    signedIn(new Resource())
  }, [signedIn]);


  const submit = useCallback((e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    signIn(usernameValue, passwordValue);
    return false;
  }, [signIn, usernameValue, passwordValue]);

  return (
    <div className="sign-in">
      <div className="auth-page__title mt-3">Please sign in</div>

      {signedInResource.isLoaded() && signedInResource.getValue() === false && resetPasswordResource.isLoaded() ? (
        <p>You have to reset your password, and will receive an email shortly.</p>
      ) : (
        <form onSubmit={e => submit(e)}>
          <div className="form-group">
            <input
              type="text"
              id="username"
              className="form-control"
              placeholder="john@doe.com"
              value={usernameValue}
              onChange={e => changeUsernameValue(e.target.value)}
              disabled={signedInResource.isLoading()}
              autoFocus={true}
            />
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="••••••••"
              value={passwordValue}
              onChange={e => changePasswordValue(e.target.value)}
              disabled={signedInResource.isLoading()}
            />
          </div>


          {signedInResource.isErrored() ? (
            <div className="alert alert-danger">Invalid username or password</div>
          ) : null}

          {signedInResource.isLoading() ? (
            <div className="d-flex align-items-center justify-content-center mt-3 py-2">
              <Loading/>
            </div>
          ) : (
            <button type="submit" className="btn btn-secondary btn-block mt-3">
              Sign in
            </button>
          )}
        </form>
      )}
    </div>
  );
};

SignIn.propTypes = propTypes;

SignIn.defaultProps = {};


export default connect((state: State) => ({
  signedInResource: getSignedIn(state),
  resetPasswordResource: getResetPasswordResource(state)
}), {
  signIn,
  signedIn
})(SignIn);
