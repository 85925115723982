import React from "react";

import BoilerList from "../../components/BoilerList/BoilerList";

import "./BoilerListPage.scss";
import Logo from "../../components/Logo/Logo";

const propTypes: any = {};

const BoilerListPage = () => {
  return (
    <div className="home-page py-3">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <Logo/>
          <h2 className="m-0">Boiler List</h2>
        </div>

        <div className="page">
          <BoilerList/>
        </div>
      </div>
    </div>
  );
};

BoilerListPage.propTypes = propTypes;

BoilerListPage.defaultProps = {};

export default BoilerListPage;
