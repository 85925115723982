import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import boilers, { BoilersState } from "./boilers";
import session, { SessionState } from "./session";

export interface State {
  router: any;
  session: SessionState;
  boilers: BoilersState;
  debug: any;
}

export interface SagaPayloadArguments<T> {
  payload: T;
}

export const history = createBrowserHistory();

const rootReducer = combineReducers<State>({
  router: connectRouter(history),
  session: session.reducer,
  boilers: boilers.reducer,
  debug: (state, action) => {
    console.debug(action.type, action.payload);
    return null;
  }
});

function* rootSaga() {
  yield all([session.watch(), boilers.watch()]);
}

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = configureStore();
