import { call, put, select, takeEvery, delay } from "@redux-saga/core/effects";
import {
  ADD_BOILER,
  BOILER_WRITE_CENTRAL_HEATING_OUTPUT,
  BOILER_WRITE_DOMESTIC_HOT_WATER_OUTPUT,
  BOILER_WRITE_SERVICE_TIMER,
  boilerLoaded,
  boilersLoaded,
  getBoiler,
  LOAD_BOILER,
  LOAD_BOILERS,
  LOAD_WATER_PRESSURE,
  loadBoilers, UPDATE_UPRN,
  waterPressureLoaded
} from "./boilers";

import API from "../API";
import BoilerDto from "./dtos/boiler.dto";
import { Resource, ResourceStatus } from "../utils/resource";
import { SagaPayloadArguments } from "./index";
import BoilerSystemDto from "./dtos/boiler-system.dto";
import { WaterPressureDto } from "./dtos/water-pressure.dto";
import RequestError from "../Exceptions/request.error";

class BoilersSagas {
  * loadResource<T>(action: CallableFunction, getValueCallback: () => any) {
    let resource = new Resource<T>();
    resource.setStatus(ResourceStatus.Loading);
    yield put(action(resource));

    resource = new Resource<T>();
    try {
      const value = yield getValueCallback();
      resource.setValue(value);
    } catch (error) {
      resource.setError(error);
    }

    yield put(action(resource));
  }

  * loadBoilersSaga({ payload: { search } }: SagaPayloadArguments<{ search: string; }>) {
    yield this.loadResource<BoilerDto[]>(boilersLoaded, () => call(API.getBoilers, search));
  }

  * loadBoilerSaga({ payload: { serialNumber } }: SagaPayloadArguments<{ serialNumber: string; }>) {
    yield this.loadResource<BoilerDto>(boilerLoaded, () => call(API.getBoiler, serialNumber));
  }

  * addBoilerSaga({ payload: { serialNumber, contractNumber } }: SagaPayloadArguments<{ serialNumber: string, contractNumber:string }>) {
    try{
      const result = yield call(API.addBoiler, serialNumber, contractNumber);
      if(result.status === "ERROR"){
        alert("Sorry, there was an error adding your boiler. Please contact your Sales Representative.");
      }
      yield delay(1000);
      yield put(loadBoilers());
    } catch(error){
      console.error("Error adding a boiler", error);
      alert("Could not add boiler");
    }
  }

  * loadBoilerWaterPressureSaga() {
    const boilerResource = yield select(getBoiler);
    const boiler = boilerResource.getValue() as BoilerDto;

    let resource = new Resource<WaterPressureDto[]>();
    resource.setStatus(ResourceStatus.Loading);
    yield put(waterPressureLoaded(resource));

    const system = boiler.system as BoilerSystemDto;
    const gatewaySerialNumber = system.devices.gateway.serialNumber;
    const boilerSerialNumber = system.devices.boiler.serialNumber;

    resource = new Resource<WaterPressureDto[]>();
    try {
      const history = yield call(API.getWaterPressureHistory, gatewaySerialNumber, boilerSerialNumber);
      resource.setValue(history);
    } catch (err) {
      console.error(err);
      resource.setError(err);
    }

    yield put(waterPressureLoaded(resource));
  }

  * boilerWriteSettings(resourceName: string, actionResource: Resource, data: any) {
    const boilerResource: Resource<BoilerDto> = yield select(getBoiler);
    const boiler = boilerResource.getValue() as BoilerDto;

    actionResource.setStatus(ResourceStatus.Loading);
    yield put(boilerLoaded(boilerResource));

    if (!boiler.system || !boiler.system.devices.gateway) {
      throw new Error("Cannot update boiler without gateway details");
    }

    const gatewaySerialNumber = boiler.system.devices.gateway.serialNumber;
    if (!gatewaySerialNumber) {
      throw new Error("Cannot update boiler without gateway serial number");
    }

    const boilerSerialNumber = boiler.system.devices.boiler.serialNumber;
    if (!boilerSerialNumber) {
      throw new Error("Cannot update boiler without boiler serial number");
    }

    const send = { type: "BOILER", ...data };

    try {
      yield call(API.updateBoilerSettings, gatewaySerialNumber, boilerSerialNumber, send);
      yield this.loadBoilerSaga({ payload: { serialNumber: boiler.serialNumber } });
    } catch (err) {

      if(err instanceof RequestError){
        console.log(err.body);
        alert(`Sorry, there was an error.\n\n${err.body.error || ""}`);
        return;
      }

      console.error(err);
      actionResource.setError(err);
      yield put(boilerLoaded(boilerResource));
    }
  }

  * boilerWriteServiceTimerSaga({ payload: { value } }: SagaPayloadArguments<{ boiler: BoilerDto; value: any; }>) {
    const boilerResource: Resource<BoilerDto> = yield select(getBoiler);
    const diagnostics = (boilerResource.getValue() as BoilerDto).getBoilerDiagnostics();
    if (!diagnostics) {
      throw new Error("Cannot update central heating output without loaded diagnostics");
    }

    yield this.boilerWriteSettings('updateServiceTimerAction', diagnostics.updateServiceTimerAction, {
      hoursTillService: value
    });
  }

  * boilerWriteCentralHeatingOutputSaga({ payload: { value } }: SagaPayloadArguments<{ boiler: BoilerDto; value: any; }>) {
    const boilerResource: Resource<BoilerDto> = yield select(getBoiler);
    const diagnostics = (boilerResource.getValue() as BoilerDto).getBoilerDiagnostics();
    if (!diagnostics) {
      throw new Error("Cannot update central heating output without loaded diagnostics");
    }

    yield this.boilerWriteSettings('updateCentralHeatingAction', diagnostics.updateCentralHeatingAction, {
      centralHeating: {
        powerOutput: value
      }
    });
  }

  * boilerWriteDomesticHotWaterOutputSaga({ payload: { boiler, value } }: SagaPayloadArguments<{ boiler: BoilerDto; value: any; }>) {
    const boilerResource: Resource<BoilerDto> = yield select(getBoiler);
    const diagnostics = (boilerResource.getValue() as BoilerDto).getBoilerDiagnostics();
    if (!diagnostics) {
      throw new Error("Cannot update central heating output without loaded diagnostics");
    }

    yield this.boilerWriteSettings('updateDomesticHotWaterAction', diagnostics.updateDomesticHotWaterAction, {
      domesticHotWater: {
        powerOutput: value
      }
    });
  }

  * updateUprnSaga({ payload: { serialNumber, uprn } }: SagaPayloadArguments<{ serialNumber: string; uprn: string}>){

    const boilerResource: Resource<BoilerDto> = yield select(getBoiler);

    try {
      yield call(API.updateUprn, serialNumber, uprn);
      yield this.loadBoilerSaga({ payload: { serialNumber: serialNumber } });
    } catch (err) {
      console.error(err);
      yield put(boilerLoaded(boilerResource));
    }

  }
}

export function* watch() {
  const sagas = new BoilersSagas();

  yield takeEvery(LOAD_BOILERS as any, sagas.loadBoilersSaga.bind(sagas));
  yield takeEvery(LOAD_BOILER as any, sagas.loadBoilerSaga.bind(sagas));
  yield takeEvery(ADD_BOILER as any, sagas.addBoilerSaga.bind(sagas));
  yield takeEvery(UPDATE_UPRN as any, sagas.updateUprnSaga.bind(sagas));
  yield takeEvery(BOILER_WRITE_SERVICE_TIMER as any, sagas.boilerWriteServiceTimerSaga.bind(sagas));
  yield takeEvery(BOILER_WRITE_CENTRAL_HEATING_OUTPUT as any, sagas.boilerWriteCentralHeatingOutputSaga.bind(sagas));
  yield takeEvery(BOILER_WRITE_DOMESTIC_HOT_WATER_OUTPUT as any, sagas.boilerWriteDomesticHotWaterOutputSaga.bind(sagas));
  yield takeEvery(LOAD_WATER_PRESSURE as any, sagas.loadBoilerWaterPressureSaga.bind(sagas));
}
