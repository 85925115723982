export default class ModalActionDto {
  text = "";
  activate = () => {};

  constructor(action?: ModalActionDto) {
    if (action) {
      this.text = action.text;
      this.activate = action.activate;
    }
  }
}
