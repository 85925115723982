import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PencilSquare } from "react-bootstrap-icons";

import BoilerDto from "../../store/dtos/boiler.dto";
import BoilerMonitoring from "../BoilerMonitoring/BoilerMonitoring";
import BoilerCode from "../BoilerCode/BoilerCode";
import BoilerIssues from "../BoilerDiagnostics/BoilerDiagnostics";
import BoilerValues from "../BoilerValues/BoilerValues";
import { loadBoiler, updateUprn } from "../../store/boilers";

import "./BoilerDetails.scss";
import Modal from "../Modal/Modal";
import ModalActionDto from "../Modal/modal-action.dto";

interface BoilerDetailsProps {
  boiler: BoilerDto;
  updateUprn: CallableFunction;
}

const propTypes: any = {
  boiler: PropTypes.instanceOf(BoilerDto).isRequired,
  updateUprn: PropTypes.func.isRequired
};

const BoilerDetails = ({ boiler, updateUprn }: BoilerDetailsProps) => {
  const [uprn, setUprn] = useState(boiler.uprn);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="boiler-details">

      <Modal
        modalTitle="Edit UPRN"
        show={showModal}
        close={() => setShowModal(false)}
        actions={[
          new ModalActionDto({
            text: "Save",
            activate: () => {
              updateUprn(boiler.serialNumber, uprn);
              setShowModal(false);
            }
          })
        ]}
      >
        <div className="form-group">
          <label htmlFor="uprn" className="form-label">
            UPRN
          </label>
          <input
            id="uprn"
            placeholder="UPRN..."
            className="form-control"
            value={uprn}
            onChange={e => setUprn(e.target.value)}
          />
        </div>
      </Modal>

      <div className="card-title h3 m-0 mb-2">
        Appliance Information
      </div>
      <div className="card-title m-0 mb-2">
        {boiler.system ? boiler.system.getName() : "Unknown"}
      </div>

      <div className="d-flex small">
        <div className="text-muted mr-1">Serial Number:</div>
        <div>{boiler.serialNumber}</div>
      </div>
      <div className="d-flex small">
        <div className="text-muted mr-1">UPRN:</div>
        <div>
          {boiler.uprn}
          <button className="btn m-0 p-0 btn-edit-uprn" onClick={() => setShowModal(true)}>
            <PencilSquare />
          </button>
        </div>
      </div>
      <div className="d-flex small">
        <div className="text-muted mr-1">Production Year:</div>
        <div>
          {boiler.system
            ? boiler.system.getProductionYear()
            : null}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <BoilerMonitoring boiler={boiler}/>
        </div>
        <div className="col-12 col-md-6">
          <div className="row h-100">
            <div className="col-12 col-md-6">
              <BoilerCode boiler={boiler}/>
            </div>
            <div className="col-12 col-md-6">
              <BoilerIssues boiler={boiler}/>
            </div>
            <div className="col-12 mt-4 d-flex align-items-end">
              <BoilerValues boiler={boiler}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BoilerDetails.propTypes = propTypes;

BoilerDetails.defaultProps = {};

export default connect(null, {
  loadBoiler,
  updateUprn
})(BoilerDetails);
