import React from "react";
import PropTypes from "prop-types";
import BoilerDto from "../../store/dtos/boiler.dto";
import { NotificationType } from "../../store/dtos/notification.dto";

interface BoilerListStatusProps {
  boiler: BoilerDto;
}

const propTypes: any = {
  boiler: PropTypes.instanceOf(BoilerDto).isRequired
};

const BoilerListStatus = ({ boiler }: BoilerListStatusProps) => {
  const failures = boiler.getFailures();
  if (!failures) {
    return null;
  }

  if (!failures.length) {
    return <>Ok</>;
  }

  return (
    <>
      {failures.map((notification, i) => {
        let text;
        if (notification.type === NotificationType.Warning) {
          text = `Warning ${notification.getShortType()}.${notification.code}`;
        } else {
          text = `Failure ${notification.getShortType()}.${notification.code}`;
        }

        return <div key={i}>{text}</div>;
      })}
    </>
  );
};

BoilerListStatus.propTypes = propTypes;

BoilerListStatus.defaultProps = {};

export default BoilerListStatus;
