class BoilerSystemDeviceDto {
  serialNumber = "";
  marketingName = "";
  nomenclature = "";
  brand = "";
  articleNumber = "";

  constructor(device?: BoilerSystemDeviceDto) {
    if (device) {
      this.serialNumber = device.serialNumber;
      this.marketingName = device.marketingName;
      this.nomenclature = device.nomenclature;
      this.brand = device.brand;
      this.articleNumber = device.articleNumber;
    }
  }
}

class BoilerSystemDevicesBoilerDto extends BoilerSystemDeviceDto {}

class BoilerSystemDevicesGatewayDto extends BoilerSystemDeviceDto {
  installationDate: string | null = null;

  constructor(gateway?: BoilerSystemDevicesGatewayDto) {
    super(gateway);

    if (gateway) {
      this.installationDate = gateway.installationDate;
    }
  }
}

class BoilerSystemDevicesControlDto extends BoilerSystemDeviceDto {}

class BoilerSystemDevicesThermostatDto extends BoilerSystemDeviceDto {}

class BoilerSystemDevicesDto {
  boiler = new BoilerSystemDevicesBoilerDto();
  gateway = new BoilerSystemDevicesGatewayDto();
  control = new BoilerSystemDevicesControlDto();
  thermostat = new BoilerSystemDevicesThermostatDto();

  constructor(devices?: BoilerSystemDevicesDto) {
    if (devices) {
      this.boiler = new BoilerSystemDevicesBoilerDto(devices.boiler);
      this.gateway = new BoilerSystemDevicesGatewayDto(devices.gateway);
      this.control = new BoilerSystemDevicesControlDto(devices.control);
      this.thermostat = new BoilerSystemDevicesThermostatDto(
        devices.thermostat
      );
    }
  }

  getName() {
    return (
      this.boiler.marketingName ||
      this.gateway.marketingName ||
      this.control.marketingName ||
      this.thermostat.marketingName ||
      "Unknown"
    );
  }
}

class BoilerSystemServiceContactDto {
  email = "";
  phone = "";
  name = "";

  constructor(contact?: BoilerSystemServiceContactDto) {
    if (contact) {
      this.email = contact.email;
      this.phone = contact.phone;
      this.name = contact.name;
    }
  }
}

export default class BoilerSystemDto {
  name = "";
  location = "";
  overTheAirUpdateEnabled = false;
  devices = new BoilerSystemDevicesDto();
  serviceContact = new BoilerSystemServiceContactDto();

  constructor(system?: BoilerSystemDto) {
    if (system) {
      this.name = system.name;
      this.location = system.location;
      this.overTheAirUpdateEnabled = system.overTheAirUpdateEnabled;
      this.devices = new BoilerSystemDevicesDto(system.devices);
      this.serviceContact = new BoilerSystemServiceContactDto(
        system.serviceContact
      );
    }
  }

  getName() {
    if (this.name) {
      return this.name;
    }

    return this.devices.getName();
  }

  getProductionYear() {
    const date = this.devices.gateway.installationDate;
    if (!date) {
      return "20" + this.devices.boiler.serialNumber.slice(2, 4);
    }

    return new Date(date).getFullYear();
  }
}
