import React from "react";
import PropTypes from "prop-types";

import Loading from "../Loading/Loading";
import { Resource, ResourceStatus } from "../../utils/resource";

interface LoadingWrapperProps {
  children: any;
  resource: Resource;
  errorMessage: any;
}

const propTypes: any = {
  children: PropTypes.node,
  resource: PropTypes.instanceOf(Resource).isRequired,
  errorMessage: PropTypes.string
};

const LoadingWrapper = ({ resource, children, errorMessage }: LoadingWrapperProps) => {
  switch (resource.getStatus()) {
    case ResourceStatus.Loading:
    case ResourceStatus.Initial:
      return (<Loading/>);

    case ResourceStatus.Errored:
      if (errorMessage) {
        return errorMessage;
      }

      const error = resource.getError();
      return error
        ? "Unable to fetch"
        : null;

    default:
      return children;
  }
};

LoadingWrapper.propTypes = propTypes;

LoadingWrapper.defaultProps = {};

export default LoadingWrapper;
