import React from "react";
import PropTypes from "prop-types";

interface ListPaginationProps {
  pageIndex: number;
  pageSize: number;
  total: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: CallableFunction;
}

const propTypes: any = {
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  gotoPage: PropTypes.func.isRequired
};

const ListPagination = ({ pageIndex, pageSize, total, canPreviousPage, canNextPage, gotoPage }: ListPaginationProps) => {
  return (
    <div className="boiler-list__pagination">
      <span className="mr-3 small text-muted">
        Viewing&nbsp;
        <b>
          {pageIndex * pageSize + 1}-{(pageIndex + 1) * pageSize}
        </b>{" "}
        of <b>{total}</b>
      </span>
      <button
        className="btn btn-light btn-sm mr-1"
        disabled={!canPreviousPage}
        onClick={() => gotoPage(pageIndex - 1)}
      >
        Prev
      </button>
      <button
        className="btn btn-light btn-sm"
        disabled={!canNextPage}
        onClick={() => gotoPage(pageIndex + 1)}
      >
        Next
      </button>
    </div>
  );
};

ListPagination.propTypes = propTypes;

ListPagination.defaultProps = {};

export default ListPagination;
