import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import LoginPage from "../AuthPage/AuthPage";
import BoilerPage from "../BoilerPage/BoilerPage";
import BoilerListPage from "../BoilerListPage/BoilerListPage";
import { getSignedIn } from "../../store/session";
import { history, State } from "../../store";
import { Resource } from "../../utils/resource";
import ErrorPage from "../ErrorPage/ErrorPage";

interface RoutingProps {
  signedIn: Resource;
}

const propTypes = {
  signedIn: PropTypes.instanceOf(Resource).isRequired
};

const Routing: any = ({ signedIn }: RoutingProps) => {

  return (
    <ConnectedRouter history={history}>
      {!signedIn.isLoaded() ? (
        <Switch>
          <Route path="/error" component={ErrorPage}/>
          <Route path="/*" component={LoginPage}/>
        </Switch>
      ):(
        <Switch>
          <Route path="/error" component={ErrorPage}/>
          <Route path="/:id" component={BoilerPage}/>
          <Route path="/" component={BoilerListPage}/>
        </Switch>
      )}

    </ConnectedRouter>
  );
};

Routing.propTypes = propTypes;

Routing.defaultProps = {};

export default connect((state: State) => ({
  signedIn: getSignedIn(state)
}))(Routing);
