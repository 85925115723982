export const NotificationType = {
  Status: "STATUS",
  Warning: "WARNING",
  Error: "ERROR"
};
export const NotificationShortType = {
  Status: "S",
  Warning: "M",
  Error: "F"
};

class NotificationDeviceDto {
  articleNumber = "";
  serialNumber = "";

  constructor(deviceDto?: NotificationDeviceDto) {
    if (deviceDto) {
      this.articleNumber = deviceDto.articleNumber;
      this.serialNumber = deviceDto.serialNumber;
    }
  }
}

export default class NotificationDto {
  id = "";
  code = 0;
  type = "";
  device = new NotificationDeviceDto();
  gateway = new NotificationDeviceDto();
  description: string | null = null;

  constructor(dto?: NotificationDto) {
    if (dto) {
      Object.assign(this, dto);

      this.device = new NotificationDeviceDto(dto.device);
      this.gateway = new NotificationDeviceDto(dto.gateway);
    }
  }

  getShortType() {
    switch (this.type) {
      case NotificationType.Status:
        return NotificationShortType.Status;
      case NotificationType.Warning:
        return NotificationShortType.Warning;
      case NotificationType.Error:
        return NotificationShortType.Error;
      default:
        return null;
    }
  }
}
