// imports
import { watch } from "./boilers.sagas";
import { createAction } from "../utils/saga-utils";
import BoilerDto from "./dtos/boiler.dto";
import { State } from "./index";
import { Resource } from "../utils/resource";
import { WaterPressureDto } from "./dtos/water-pressure.dto";

// constants
export const LOAD_BOILERS = "BOILERS:LOAD";
export const BOILERS_LOADED = "BOILERS:LOADED";
export const LOAD_BOILER = "BOILERS:LOAD_SINGLE";
export const BOILER_LOADED = "BOILER:LOADED";
export const ADD_BOILER = "BOILER:ADD";
export const BOILER_WRITE_SERVICE_TIMER = "BOILER:WRITE_SERVICE_TIMER";
export const BOILER_WRITE_CENTRAL_HEATING_OUTPUT = "BOILER:WRITE_CENTRAL_HEATING_OUTPUT";
export const BOILER_WRITE_DOMESTIC_HOT_WATER_OUTPUT = "BOILER:WRITE_DOMESTIC_HOT_WATER_OUTPUT";
export const LOAD_WATER_PRESSURE = "BOILERS:LOAD_WATER_PRESSURE";
export const WATER_PRESSURE_LOADED = "BOILERS:WATER_PRESSURE_LOADED";
export const UPDATE_UPRN = "BOILERS:UPDATE_UPRN";

// actions
export const loadBoilers = createAction(LOAD_BOILERS, (search: string) => ({ search }));
export const boilersLoaded = createAction(BOILERS_LOADED, (list: Resource<BoilerDto[]>) => ({ list }));
export const loadBoiler = createAction(LOAD_BOILER, (serialNumber: string) => ({ serialNumber }));
export const updateUprn = createAction(UPDATE_UPRN, (serialNumber: string, uprn: string) => ({serialNumber, uprn}));
export const boilerLoaded = createAction(BOILER_LOADED, (boiler: Resource<BoilerDto>) => ({ boiler }));
export const addBoiler = createAction(ADD_BOILER, (serialNumber: string, contractNumber: string) => ({ serialNumber, contractNumber }));
export const boilerWriteServiceTimer = createAction(BOILER_WRITE_SERVICE_TIMER, (boiler: BoilerDto, value: number) => ({ boiler, value }));
export const boilerWriteCentralHeatingOutput = createAction(BOILER_WRITE_CENTRAL_HEATING_OUTPUT, (boiler: BoilerDto, value: number) => ({ boiler, value }));
export const boilerWriteDomesticHotWaterOutput = createAction(BOILER_WRITE_DOMESTIC_HOT_WATER_OUTPUT, (boiler: BoilerDto, value: number) => ({ boiler, value }));
export const loadWaterPressure = createAction(LOAD_WATER_PRESSURE);
export const waterPressureLoaded = createAction(WATER_PRESSURE_LOADED, (waterPressure: Resource<WaterPressureDto[]>) => ({ waterPressure }));

export interface BoilersState {
  list: Resource<BoilerDto[]>;
  boiler: Resource<BoilerDto>;
  waterPressure: Resource<WaterPressureDto[]>;
}

// initial state
const initialState: BoilersState = {
  list: new Resource<BoilerDto[]>(),
  boiler: new Resource<BoilerDto>(),
  waterPressure: new Resource<WaterPressureDto[]>()
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BOILERS_LOADED:
      return { ...state, list: action.payload.list, boiler: new Resource() };
    case BOILER_LOADED:
      return { ...state, list: new Resource(), boiler: action.payload.boiler, waterPressure: new Resource() };
    case WATER_PRESSURE_LOADED:
      return { ...state, waterPressure: action.payload.waterPressure };
    default:
      return state;
  }
};

// selectors
export const getBoilers = (state: State) => state.boilers.list;
export const getBoiler = (state: State) => state.boilers.boiler;
export const getWaterPressure = (state: State) => state.boilers.waterPressure;

// exports
export default { reducer, watch };
