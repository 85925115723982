import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getSignedIn, signOut } from "../../store/session";
import { Resource } from "../../utils/resource";

import logo from "../../assets/images/vaillant-logo.jpg";

import "./Header.scss";
import Logo from "../Logo/Logo";
import { State } from "../../store";

interface HeaderProps {
  signedIn: Resource;
  signOut: CallableFunction;
}

const propTypes: any = {
  signOut: PropTypes.func.isRequired,
  signedIn: PropTypes.instanceOf(Resource).isRequired
};

const Header = ({ signedIn, signOut }: HeaderProps) => {
  return (
    <div className="header mx-auto px-2">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="header__logo">
          <img src={logo} alt="logo" className="header__logo__image"/>
        </div>
        <div className="header__social-assist-logo">
          <Logo/>
        </div>
        <div className="header__buttons">
          {signedIn.isLoaded() ? (
            <button className="btn btn-light" onClick={() => signOut()}>
              Sign out
            </button>
          ) : <>&nbsp;</>}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

Header.defaultProps = {};

export default connect((state: State) => ({
  signedIn: getSignedIn(state)
}), {
  signOut
})(Header);
