import { Resource } from "../../utils/resource";

class BoilerDiagnosticsTemperaturesDto {
  flowTemperature = 0;
  returnTemperature = 0;

  constructor(dto?: BoilerDiagnosticsTemperaturesDto) {
    if (dto) {
      this.flowTemperature = dto.flowTemperature;
      this.returnTemperature = dto.returnTemperature;
    }
  }
}

class BoilerDiagnosticsCentralHeatingDto {
  blocked = false;
  burnerStarts = 0;
  operatingHours = 0;
  waterPressure = 0;
  powerOutput = 0;
  temperatures = new BoilerDiagnosticsTemperaturesDto();

  constructor(dto?: BoilerDiagnosticsCentralHeatingDto) {
    if (dto) {
      this.blocked = dto.blocked;
      this.burnerStarts = dto.burnerStarts;
      this.operatingHours = dto.operatingHours;
      this.waterPressure = dto.waterPressure;
      this.powerOutput = dto.powerOutput;
      this.temperatures = new BoilerDiagnosticsTemperaturesDto(dto.temperatures);
    }
  }
}

class BoilerDiagnosticsDomesticHotWaterDto {
  burnerStarts = 0;
  operatingHours = 0;
  powerOutput = 25;

  constructor(dto?: BoilerDiagnosticsDomesticHotWaterDto) {
    if (dto) {
      this.burnerStarts = dto.burnerStarts;
      this.operatingHours = dto.operatingHours;
      this.powerOutput = dto.powerOutput;
    }
  }
}

export default class BoilerDiagnosticsDto {
  type: string | null = null;
  averageIgnitionTime = 0;
  hoursTillService: string | null = null;
  generatorStatus = 0;
  heatingDemand = false;
  modulationLevel = 0;
  threeWayValve = "";
  centralHeating = new BoilerDiagnosticsCentralHeatingDto();
  domesticHotWater = new BoilerDiagnosticsDomesticHotWaterDto();

  updateServiceTimerAction = new Resource();
  updateCentralHeatingAction = new Resource();
  updateDomesticHotWaterAction = new Resource();

  constructor(dto?: BoilerDiagnosticsDto) {
    if (dto) {
      Object.assign(this, dto);

      this.centralHeating = new BoilerDiagnosticsCentralHeatingDto(dto.centralHeating);
      this.domesticHotWater = new BoilerDiagnosticsDomesticHotWaterDto(dto.domesticHotWater);
    }
  }

  getThreeWayValveText() {
    switch (this.threeWayValve) {
      case "CENTRAL_HEATING":
        return "Central heating";
      default:
        return "Unknown";
    }
  }
}
