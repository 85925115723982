// imports
import { put, takeEvery, fork } from "@redux-saga/core/effects";
import API from "../API";
import { Resource, ResourceStatus } from "../utils/resource";
import { createAction } from "../utils/saga-utils";
import { SagaPayloadArguments, State } from "./index";
import { SignUpDto } from "./dtos/sign-up.dto";

export interface SessionState {
  signedIn: Resource<boolean>;
  resetPasswordResource: Resource<boolean>;
}

// constants
export const SIGN_IN = "SESSION_SIGN_IN";
export const SIGN_UP = "SESSION_SIGN_UP";
export const SIGNED_IN = "SESSION_SIGNED_IN";
export const SIGN_OUT = "SESSION_SIGN_OUT";
export const SIGNED_OUT = "SESSION_SIGNED_OUT";
export const SUBMIT_RESET_PASSWORD = "SESSION_SUBMIT_RESET_PASSWORD";
export const RESET_PASSWORD_SUBMITTED = "SESSION_RESET_PASSWORD_SUBMITTED";


// actions
export const signIn = createAction(SIGN_IN, (username: string, password: string) => ({ username, password }));
export const signUp = createAction(SIGN_UP, (dto: SignUpDto) => ({ dto }));
export const signedIn = createAction(SIGNED_IN, (signedIn: Resource<boolean>) => ({ signedIn }));
export const signOut = createAction(SIGN_OUT);
export const signedOut = createAction(SIGNED_OUT);
export const submitResetPassword = createAction(SUBMIT_RESET_PASSWORD, (username: string) => ({ username }));
export const resetPasswordSubmitted = createAction(RESET_PASSWORD_SUBMITTED, (resetPasswordResource: Resource<boolean>) => ({ resetPasswordResource }));


// initial state
const initialState: SessionState = {
  signedIn: new Resource<boolean>(true),
  resetPasswordResource: new Resource<boolean>()
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGNED_IN:
      return {
        ...state,
        signedIn: action.payload.signedIn
      };
    case SIGNED_OUT:
      return {
        ...state,
        signedIn: new Resource<boolean>()
      };
    case RESET_PASSWORD_SUBMITTED:
      return {
        ...state,
        resetPasswordResource: action.payload.resetPasswordResource
      };

    default:
      return state;
  }
};

// sagas
function* signInSaga({ payload: { username, password } }: SagaPayloadArguments<{ username: string; password: string; }>) {
  let signedInResource = new Resource();
  signedInResource.setStatus(ResourceStatus.Loading);

  yield put(signedIn(signedInResource));
  signedInResource = new Resource();

  try {
    const { result } = yield API.signIn(username, password);
    if (result === "password-creation-email-triggered") {
      const resetPasswordResource = new Resource(false);
      resetPasswordResource.setStatus(ResourceStatus.Loaded);
      yield put(resetPasswordSubmitted(resetPasswordResource));
      signedInResource.setValue(false);
    } else {
      signedInResource.setValue(true);
    }
  } catch (error) {
    signedInResource.setError(error);
  }

  yield put(signedIn(signedInResource));
}

function* signUpSaga({ payload: { dto } }: SagaPayloadArguments<{ dto: SignUpDto }>) {
  let resource = new Resource();
  resource.setStatus(ResourceStatus.Loading);

  yield put(signedIn(resource));
  resource = new Resource();

  try {
    yield API.signUp(dto);
    yield API.signIn(dto.email, dto.password);
    resource.setValue(true);
  } catch (error) {
    resource.setError(error);
  }

  yield put(signedIn(resource));
}

function* checkAuthStatus(){
  try {
    const loggedIn = yield API.checkAuth();
    if(loggedIn.result){
      const resource = new Resource();
      resource.setValue(true);
      yield put(signedIn(resource));
    }
  } catch(error){
    console.log("Auth check failed", error);
  }
}

function* signOutSaga(){
  try {
    yield API.signOut();
    yield put(signedOut());
  } catch (error){
    console.log("Problem signing out", error);
  }
}
function* resetPasswordSaga({ payload: { username } }: SagaPayloadArguments<{ username: string }>) {
  let resource = new Resource();
  resource.setStatus(ResourceStatus.Loading);

  yield put(resetPasswordSubmitted(resource));
  resource = new Resource();

  try {
    yield API.submitResetPassword(username);
    resource.setValue(false);
  } catch (error) {
    resource.setError(error);
  }

  yield put(resetPasswordSubmitted(resource));
}

export function* watch() {
  yield fork(checkAuthStatus);
  yield takeEvery(SIGN_IN as any, signInSaga);
  yield takeEvery(SIGN_UP as any, signUpSaga);
  yield takeEvery(SIGN_OUT as any, signOutSaga);
  yield takeEvery(SUBMIT_RESET_PASSWORD as any, resetPasswordSaga);
}

// selectors
export const getSignedIn = (state: State) => state.session.signedIn;
export const getResetPasswordResource = (state: State) => state.session.resetPasswordResource;

// exports
export default { reducer, watch };
