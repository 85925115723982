import React from "react";
import PropTypes from "prop-types";

let ids = 0;

export enum InputType {
  Text = "text",
  Password = "password"
}

interface InputProps {
  onChange: (value: string) => any;
  label: string;
  value: string;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
}

const propTypes: any = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(InputType)).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

const Input = ({ onChange, label, type, value, placeholder, disabled }: InputProps) => {
  const id = ids++;

  return (
    <div className="form-group">
      <label htmlFor={"input-" + id} className="form-label">{label}</label>
      <input
        id={"input-" + id}
        type={type}
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

Input.propTypes = propTypes;

Input.defaultProps = {
  type: InputType.Text
};

export default Input;
