export enum ResourceStatus {
  Initial = "initial",
  Loading = "loading",
  Loaded = "loaded",
  Errored = "errored"
}

export class Resource<T = any> {
  private readonly subscriptions: CallableFunction[] = [];
  private status = ResourceStatus.Initial;
  private error: Error | null = null;
  private value?: T;

  constructor(value?: T) {
    if (value) {
      this.value = value;
    }
  }

  getValue() {
    return this.value;
  }

  setValue(value: T) {
    this.value = value;
    this.setStatus(ResourceStatus.Loaded);

    if (this.subscriptions.length) {
      [...this.subscriptions].forEach(subscription => {
        subscription(null, value);
      });
    }
  }

  getStatus() {
    return this.status;
  }

  setStatus(resourceStatus: ResourceStatus) {
    if (!Object.values(ResourceStatus).includes(resourceStatus)) {
      throw new Error("Invalid resource status: " + resourceStatus);
    }

    this.status = resourceStatus;
  }

  getError() {
    return this.error;
  }

  setError(error: Error) {
    this.error = error;
    this.setStatus(ResourceStatus.Errored);

    if (this.subscriptions.length) {
      [...this.subscriptions].forEach(subscription => {
        subscription(error);
      });
    }
  }

  isInitial() {
    return this.status === ResourceStatus.Initial;
  }

  isLoading() {
    return this.status === ResourceStatus.Loading;
  }

  isLoaded() {
    return this.status === ResourceStatus.Loaded;
  }

  isErrored() {
    return this.status === ResourceStatus.Errored;
  }

  awaitValue(): Promise<T> {
    if (this.isLoaded()) {
      return Promise.resolve(this.getValue() as T);
    }

    if (this.isErrored()) {
      return Promise.reject(this.getError());
    }

    return new Promise((resolve, reject) => {
      const subscription = (err: Error, value: T) => {
        this.subscriptions.splice(
          this.subscriptions.indexOf(subscription),
          1
        );

        if (err) {
          reject(err);
        } else {
          resolve(value);
        }
      };

      this.subscriptions.push(subscription);
    });
  }
}
