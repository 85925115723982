import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import moment from "moment";
import Modal from "../Modal/Modal";
import { connect } from "react-redux";
import { State } from "../../store";
import { getWaterPressure, loadWaterPressure } from "../../store/boilers";
import { Resource } from "../../utils/resource";
import { WaterPressureDto } from "../../store/dtos/water-pressure.dto";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import BoilerDto from "../../store/dtos/boiler.dto";

interface BoilerWaterPressureChartProps {
  show: boolean;
  close: CallableFunction;
  boiler: BoilerDto;
  loadWaterPressure: CallableFunction;
  waterPressure: Resource<WaterPressureDto[]>;
}

const propTypes: any = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  boiler: PropTypes.instanceOf(BoilerDto).isRequired,
  loadWaterPressure: PropTypes.func.isRequired,
  waterPressure: PropTypes.instanceOf(Resource).isRequired
};

const BoilerWaterPressureChart = ({ show, close, loadWaterPressure, waterPressure }: BoilerWaterPressureChartProps) => {
  useEffect(() => {
    if (show && waterPressure.isInitial()) {
      loadWaterPressure();
    }
  }, [show, loadWaterPressure, waterPressure]);


  let chartData: any;
  if (waterPressure.isLoaded()) {
    const waterPressureData = waterPressure.getValue() as WaterPressureDto[];
    chartData = waterPressureData.reduce((obj, el, i) => {
      const date = new Date(el.timestamp);
      obj.labels.push(date);
      obj.data.push(el.value);
      return obj;
    }, { labels: [], data: [] } as { labels: Date[]; data: number[]; });
  }

  return (
    <Modal show={show} modalTitle="Water pressure" close={close} actions={[]}>
      <p className="text-center small mb-4">
        Water pressure can fluctuate between 1-2 bar during normal use.
      </p>

      <LoadingWrapper resource={waterPressure} errorMessage="Unable to load water pressure">
        {chartData ? (
          <Line
            data={{
              labels: chartData.labels,
              datasets: [
                {
                  label: "Pressure in bar",
                  data: chartData.data
                }
              ]
            }}
            options={{
              responsive: true,
              title: {
                display: false
              },
              tooltips: {
                mode: "index",
                intersect: false,
                callbacks: {
                  title: (values: any[]) => moment(values[0].xLabel).format("DD/MM"),
                  label: (tooltipItem: any) => tooltipItem.yLabel + " bar"
                }
              },
              hover: {
                mode: "nearest",
                intersect: true
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    ticks: {
                      callback: (date: any) => moment(date).format("DD/MM")
                    }
                  }
                ],
                yAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: true,
                      labelString: "Pressure in bar"
                    },
                    ticks: {
                      beginAtZero: true,
                      suggestedMin: 0.5,
                      suggestedMax: 1.5
                    }
                  }
                ]
              }
            }}
          />
        ) : null}
      </LoadingWrapper>
    </Modal>
  );
};

BoilerWaterPressureChart.propTypes = propTypes;

BoilerWaterPressureChart.defaultProps = {};

export default connect((state: State) => ({
  waterPressure: getWaterPressure(state)
}), {
  loadWaterPressure
})(BoilerWaterPressureChart);
