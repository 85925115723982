import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBoiler, loadBoiler } from "../../store/boilers";
import Loading from "../../components/Loading/Loading";
import BoilerDetails from "../../components/BoilerDetails/BoilerDetails";
import { Link } from "react-router-dom";
import BoilerDto from "../../store/dtos/boiler.dto";
import Logo from "../../components/Logo/Logo";
import { State } from "../../store";
import { Resource } from "../../utils/resource";

import "./BoilerPage.scss";

interface BoilerPageProps {
  id: string;
  boiler: Resource<BoilerDto>;
  loadBoiler: CallableFunction;
}

const propTypes: any = {
  id: PropTypes.string.isRequired,
  boiler: PropTypes.instanceOf(Resource).isRequired,
  loadBoiler: PropTypes.func.isRequired
};

const BoilerPage = ({ id, boiler, loadBoiler }: BoilerPageProps) => {
  useEffect(() => {
    const isDifferent = boiler.isLoaded() && (boiler.getValue() as BoilerDto).serialNumber !== id;
    if (isDifferent || boiler.isInitial()) {
      loadBoiler(id);
    }
  }, [id, loadBoiler, boiler]);

  return (
    <div className="boiler-page py-3">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <Logo/>
          <h2 className="m-0">Boiler Details</h2>
        </div>

        <div className="page">
          <div className="mb-3">
            <Link to="/">Back to boiler list</Link>
          </div>

          {boiler.isInitial() || boiler.isLoading() ? (
            <div className="text-center">
              <Loading/>
            </div>
          ) : boiler.isErrored() ? (
            <div className="alert alert-danger">
              Unable to find boiler with serial number {id}
            </div>
          ) : (
            <BoilerDetails boiler={boiler.getValue() as BoilerDto}/>
          )}
        </div>
      </div>
    </div>
  );
};

BoilerPage.propTypes = propTypes;

BoilerPage.defaultProps = {};

export default connect((state: State, ownProps: { match: { params: { id: string } } }) => ({
  id: ownProps.match.params.id,
  boiler: getBoiler(state)
}), {
  loadBoiler
})(BoilerPage);
