import React from "react";

const propTypes: any = {};

const BoilerDiagnostics = () => {
  return (
    <div className="boiler-diagnostics">
      <div className="h5 mb-3">Diagnostics</div>
    </div>
  );
};

BoilerDiagnostics.propTypes = propTypes;

BoilerDiagnostics.defaultProps = {};

export default BoilerDiagnostics;
