import NotificationDto, { NotificationType } from "./notification.dto";
import BoilerDiagnosticsDto from "./boiler-diagnostics.dto";
import BoilerSystemDto from "./boiler-system.dto";

export default class BoilerDto {
  serialNumber = "";
  uprn = "";
  system?: BoilerSystemDto;
  diagnostics?: BoilerDiagnosticsDto[];
  notifications?: NotificationDto[];

  constructor(dto?: BoilerDto) {
    Object.assign(this, dto);

    if (this.system) {
      this.system = new BoilerSystemDto(this.system);
    }

    if (this.diagnostics) {
      this.diagnostics = this.diagnostics.map(diagnostics => new BoilerDiagnosticsDto(diagnostics));
    }

    if (this.notifications) {
      this.notifications = this.notifications.map(notification => new NotificationDto(notification));
    }
  }

  getFailures() {
    return (this.notifications || []).filter(notification => {
      return notification.type !== NotificationType.Status;
    });
  }

  getBoilerDiagnostics() {
    return this.diagnostics ? this.diagnostics.find(diag => diag.type === "BOILER") : null;
  }
}
